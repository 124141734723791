@import "./reset.css";

body {
  position: relative;
  margin: 0;
  font-family: "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif, -apple-system;
  background-color: #ffffff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
button {
  cursor: pointer;
}

button:disabled {
  opacity: 0.6;
}

.review-table th {
  background: #fafafa;
  border: 1px solid #f2f2f2;
  padding: 38px 16px;
  text-align: center;
}
.review-table td {
  border: 1px solid #f2f2f2;
  padding: 38px 16px;
  text-align: center;
}
.ur-disabled-text {
  color: #d3d3d3;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.ant-picker-panel-container .ant-picker-month-panel .ant-picker-cell::before {
  transform: translateY(0%);
  top: auto;
}
